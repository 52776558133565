import React, {useContext} from 'react';
import {LocaleContext} from '~src/context';
import {IPage} from '~src/types';

import Providers from '~src/components/Providers';
import {PageTemplate, ContentView, Modals} from '~src/components';
// import Calculation2Organism from '../../components/organisms/Calculation2Organism';
import Seo from '~src/components/Seo';

interface ICalculation2Props extends IPage {}

const Calculation2OrganismLazy = React.lazy(() => import('~src/components/organisms/Calculation2Organism'));

const Calculation2: React.FC<ICalculation2Props> = ({location, navigate, path}) => {
  const isSSR = typeof window === 'undefined';

  const {
    pages: {calculation2},
  } = useContext(LocaleContext);
  return (
    <Providers>
      <Seo
        title={calculation2.meta.title}
        description={calculation2.meta.description}
        keywords={calculation2.meta.keywords}
        path={path}
      />
      <PageTemplate
        breadcrumbs={[...calculation2.breadcrumbs, calculation2.header1]}
        breadcrumbsSource={calculation2.breadcrumbsSource}
      >
        <ContentView>
          {/*<Calculation2Organism />*/}
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <Calculation2OrganismLazy />
            </React.Suspense>
          )}
        </ContentView>
      </PageTemplate>
      <Modals navigate={navigate} path={path} location={location} />
    </Providers>
  );
};

export default Calculation2;
